define("discourse/plugins/discourse-docs/discourse/initializers/setup-docs", ["exports", "discourse/lib/plugin-api", "I18n", "discourse/plugins/discourse-docs/lib/get-docs"], function (_exports, _pluginApi, _I18n, _getDocs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api, container) {
    const siteSettings = container.lookup("site-settings:main");
    const docsPath = (0, _getDocs.getDocs)();
    api.addKeyboardShortcut("g e", "", {
      path: "/" + docsPath
    });
    if (siteSettings.docs_add_to_top_menu) {
      api.addNavigationBarItem({
        name: "docs",
        displayName: _I18n.default.t("docs.title"),
        href: "/" + docsPath
      });
    }
  }
  var _default = _exports.default = {
    name: "setup-docs",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.docs_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8", api => initialize(api, container));
      if (siteSettings.docs_add_search_menu_tip) {
        (0, _pluginApi.withPluginApi)("0.12.6", api => {
          api.addSearchSuggestion("in:docs");
          const tip = {
            label: "in:docs",
            description: _I18n.default.t("docs.search.tip_description"),
            clickable: true,
            searchTopics: true
          };
          api.addQuickSearchRandomTip(tip);
        });
      }
      (0, _pluginApi.withPluginApi)("1.2.0", api => {
        if (siteSettings.navigation_menu !== "legacy") {
          api.addCommunitySectionLink({
            name: "docs",
            route: "docs.index",
            title: _I18n.default.t("sidebar.docs_link_title"),
            text: _I18n.default.t("sidebar.docs_link_text")
          });
        } else {
          api.decorateWidget("hamburger-menu:generalLinks", () => {
            return {
              route: "docs",
              label: "docs.title",
              className: "docs-link"
            };
          });
        }
      });
    }
  };
});